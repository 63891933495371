import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealDealingBully', 'textRevealManagingCyberBully', 'textRevealSupportingLGBT'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const ButtonDialog = makeShortcode("ButtonDialog");
const GoTo = makeShortcode("GoTo");
const Img = makeShortcode("Img");
const TextCustom = makeShortcode("TextCustom");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "helping-your-teenager-navigate-problems-with-social-rejection-and-bullying"
    }}>{`Helping your teenager navigate problems with social rejection and bullying`}</h1>
    <p>{`Teenagers are diverse in their size, shape, likes and dislikes, but one thing that many teenagers have in common is that they have experienced some form of social rejection or bullying during their school years. Bullying refers to `}<em parentName="p">{`repeated and intentional use of words or actions against someone or a group of people to cause distress and risk to their wellbeing.`}</em>{` It can be verbal, physical, social, psychological, or occur online (i.e. cyber-bullying).`}</p>
    <p>{`Bullying can be hard to spot and many teenagers want the bullying to go away without drawing attention to it. For extra tips on helping your teenager deal with bullying or social rejection, click on the following links:`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <TextReveal id="textRevealDealingBully" header="Dealing with bullying at school and school refusal. (Click to reveal)" mdxType="TextReveal">
      <div>
        <p>
          Results from an Australian study that 35% of Year 9 students have experienced some form of bullying.
          <sup>1</sup>
        </p>
        <ButtonDialog linkText="Learn More" title="Dealing with bullying at school and school refusal" mdxType="ButtonDialog">
          <p>
            If your teenager feels safe at school and has good relationships with other students and teachers, they are
            at a lower risk of developing depression and clinical anxiety. Unfortunately, issues like bullying and
            social rejection at school can make school life feel like hell for some teenagers. Being a victim of
            bullying is an awful experience and one that every parent wishes would never happen to their teenager.
          </p>
          <p>If your teenager is a victim of bullying, here are some tips to help:</p>
          <ul>
            <li>Be careful not to minimise the situation.</li>
            <li>Discuss with your teenager how they would like to deal with the bullying.</li>
            <li>
              Remain calm and supportive. Teenagers are often concerned that their parents’ actions will make bullying
              issues worse.
            </li>
            <li>Never blame the victim for being bullied.</li>
            <li>
              Become familiar with their school’s anti-bullying policy or encourage the development of one if it doesn’t
              already exist.
            </li>
          </ul>
          <p>
            Teenagers may avoid going to school as the result of bullying, social rejection or struggles with school
            work. Teenagers who avoid going to school are more likely to experience depression and clinical anxiety. If
            your teenager is refusing to go to school or avoiding school, discuss with them why and work out a solution
            together. Get professional advice and support if your teenager continues to avoid school.
          </p>
          <p>
            Check out our <GoTo to="/resources" mdxType="GoTo">Useful Resources</GoTo> page for more information on seeking
            professional help. The link for this study can be found in the <GoTo to="/appendix" mdxType="GoTo">appendix</GoTo>.
          </p>
          <p>
            <GoTo external to="https://bullyingnoway.gov.au/" mdxType="GoTo">
              Bullying. No Way!
            </GoTo>{' '}
            is a helpful Australian resource for parents and teenagers with more tips on tackling bullying.
          </p>
          <Img src="/images/m6/53.svg" alt="bullying at school" mdxType="Img" />
        </ButtonDialog>
      </div>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <TextReveal id="textRevealManagingCyberBully" header="Managing your teenager’s online activities and cyber-bullying. (Click to reveal)" mdxType="TextReveal">
      <div>
        <p>
          Cyber-bullying can happen to anyone. Research has found that 1 in 10 Australian students in Year 4 to Year 9
          say they have experienced cyber-bullying.<sup>1</sup>
        </p>
        <ButtonDialog linkText="Learn more" title="Managing your teenager’s online activities" mdxType="ButtonDialog">
          <p>For many teenagers, the Internet and their electronic devices are a big part of their social life.</p>
          <p>Here are a few tips to help your teenager make good decisions in their cyber world:</p>
          <ul>
            <li>
              Keep an eye on what your teenager is doing online (non-intrusively) by having{' '}
              <b>computers and mobile phones being used in the home’s public areas</b>. This can help limit the
              opportunities for your teenager to search for inappropriate content, or to make poor judgements online.
            </li>
            <li>
              Have your teenager guide you around the sites they use and enjoy. This will help you understand their
              online world better and give you something to talk about.
            </li>
            <li>
              A lot of cyber-bullying and other poor online behaviour happens <b>late at night</b>. Try to work out
              strategies to limit or block access to the internet after a certain time each night. For example, at a
              certain time each night (e.g. bedtime), turn off the <b>Wi-Fi</b>. It can help to also have{' '}
              <b>
                <em>every</em>
              </b>{' '}
              family member hand over their <b>mobile phones and electronic devices to a central charging place</b>.
            </li>
          </ul>
          <p>These strategies are more likely to work when the whole family has discussed and agreed to them.</p>
          <ButtonDialog linkText="Learn More" title="Dealing with cyber-bullying" mdxType="ButtonDialog">
            <h4>What is cyber-bullying?</h4>
            <p>
              Cyber-bullying is the use of technology to bully a person or group, with the intent to harm them. Examples
              of cyber-bullying may include:
            </p>
            <ul>
              <li>Abusive texts and emails.</li>
              <li>Posting unkind or threatening messages, videos or images on social media websites.</li>
              <li>Imitating or excluding others online.</li>
              <li>Inappropriate image tagging.</li>
            </ul>
            <p>
              Many teenagers do not report cyber-bullying to their parents for fear that their parents might take away
              their devices and refuse access to the internet.
            </p>
            <h4>How can I help my teenager with cyber-bullying?</h4>
            <ButtonDialog linkText="Learn More" title="Dealing with cyber-bullying" mdxType="ButtonDialog">
              <h4>Here are some strategies to help your teenager take control of cyber-bullying:</h4>
              <TextCustom customColor="blue" paragraph mdxType="TextCustom">
                Talk to your teenager about cyber-bullying before it happens. Work out strategies to address any
                potential issues and reassure your teenager that you will be there to support them.
              </TextCustom>
              <TextCustom customColor="lightGreen" paragraph mdxType="TextCustom">
                Ensure your teenager knows how to block a bully so they are no longer able to make contact.
              </TextCustom>
              <TextCustom customColor="yellow" paragraph mdxType="TextCustom">
                Talk to your teen's school if cyber-bullying involves another student. Discuss with your teenager
                beforehand what you will share with the school. The school should have a policy in place to help manage
                the issue.
              </TextCustom>
              <TextCustom customColor="purple" paragraph mdxType="TextCustom">
                Make sure that your teenager knows how to contact the website administrator or use the reporting
                function to ask for content to be removed or to report harassment.
              </TextCustom>
              <TextCustom customColor="red" paragraph mdxType="TextCustom">
                Tell your teenager not to reply to any messages from a bully. Often if bullies don't receive a response
                they will give up.
              </TextCustom>
              <TextCustom customColor="green" paragraph mdxType="TextCustom">
                Remember that if your teenager has been involved in cyber-bullying and seems particularly anxious or
                irritable, encourage your teenager to seek professional help.
              </TextCustom>
              <p>
                Check out our <GoTo to="/resources" mdxType="GoTo">Useful Resources</GoTo> page for more information on seeking
                professional help.
              </p>
            </ButtonDialog>
            <Img src="/images/m6/56.svg" alt="Using phones" mdxType="Img" />
          </ButtonDialog>
          <Img src="/images/shared/55.svg" alt="Using phones" mdxType="Img" />
        </ButtonDialog>
      </div>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <TextReveal id="textRevealSupportingLGBT" header="Supporting a teenager who may be lesbian, gay, bisexual, transgender or questioning. (Click to reveal)" mdxType="TextReveal">
      <div>
        <p>
          Teenagers who differ from the majority in their sexual orientation or gender identity may experience much less
          acceptance and are more likely to experience bullying.
        </p>
        <ButtonDialog linkText="Learn More" title="Supporting a teenager who may be lesbian, gay, bisexual, transgender or questioning" mdxType="ButtonDialog">
          <div>
            <p>
              It can be difficult for parents to accept that their teenager may be lesbian, gay, bisexual, transgender
              or questioning (LGBTQIA+). However, LGBTQIA+ teenagers who feel more accepted and supported by their
              parents are at lower risk of depression, substance abuse, and suicidal behaviours.
            </p>
            <p>
              Even if you don’t feel comfortable talking with your teenager about who they are sexually attracted to or
              which gender they identify with, there are many ways you can be supportive of your teenager.
            </p>
            <ul>
              <li>
                <b>Act the same around them.</b> In the same ways you have before, keep telling your teenager that you
                love them and show them affection. See the{' '}
                <GoTo to="/dashboard" mdxType="GoTo">
                  <i>Connect</i>
                </GoTo>{' '}
                module for tips on showing affection.
              </li>
              <li>
                <b>Be open to talk.</b> It is not likely to be just one conversation, but a series of conversations.
              </li>
              <li>
                <b>Ask your teenager who you can share this with.</b> Some teenagers may want everyone to know so that
                they don’t have to keep having the ‘coming out’ conversation. Other teenagers may not want anyone else
                to know.
              </li>
              <li>
                <b>
                  Welcome your teenager’s friends to your home, regardless of their sexual orientation or gender
                  identity.
                </b>{' '}
                Encourage other family members to respect your teenager and their friends.
              </li>
              <li>
                <b>Help your teenager connect with support or social groups</b> where they feel comfortable, safe and
                accepted. This also helps you know that they are reaching out to safe and supportive people.
              </li>
            </ul>
            <Img src="/images/m6/57.svg" alt="Using phones" mdxType="Img" />
          </div>
        </ButtonDialog>
      </div>
    </TextReveal>
  </Grid>
    </Grid>
    <sup>
  1. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      